import { Button, Card, CardBody, CardText, CardTitle, Col, Form, FormFeedback, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap"
import TradingChart from "./charts/TradingChart"
import '../../../assets/css/style.css'
import PriceChart from "./charts/PriceChart"
import * as Yup from "yup";
import { useFormik } from "formik";
import { post, del, get, put } from "../../../helpers/api_helper";
import usePrice from "hooks/usePrice";
import { useState } from "react";
import UpdatePriceModal from "./modals/UpdatePriceModal";
import NewPriceModal from "./modals/NewPriceModal";
import useAuth from "hooks/useAuth";
import CountryFilter from "pages/CountryFilter";

const Price = () => {
    const { showToast } = useAuth();
    const { currentPrice, share, setComparePrice, setCompareShare, setRefresh } = usePrice();
    const [modal_center, setmodal_center] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState('IDR');
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setFormValues(validation.values);
    };
    
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            price: currentPrice,
            share: share,
        },
        validationSchema: Yup.object().shape({
            price: Yup.number()
                .required("This value is required")
                .moreThan(0, "Buying Price must be greater than 0"),
            share: Yup.number()
                .required("This value is required")
                .moreThan(0, "Amount Paid must be greater than 0")
                .max(100, "Amount Paid must be less than or equal to 100"),
        }),
        onSubmit: async (values, {resetForm}) => {
            try {
                const response = await post('/api/admin/finance/update-price', values);
                setRefresh(response.random);
                tog_center();
                resetForm();
                showToast(response);
                setComparePrice(true);
                setCompareShare(true);
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        },
    });

    const handleConfirmSubmit = () => {
        validation.handleSubmit();
    }

    const handleConfirmChange = (e) => {
        validation.handleChange(e);
        switch (e.target.name) {
            case 'price':
                setComparePrice(e.target.value == currentPrice)
                break;
            case 'share':
                setCompareShare(e.target.value == share)
                break;
        }
        
    }
    return (
        <Row>
            <Col md={4} sm={12}>
                <h4 className="card-title mb-3">UCO Buying Price</h4>
                <div className="uco-price">
                    <div className="d-flex justify-content-start">
                        <NewPriceModal/>
                    </div>
                    
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div className="mb-3">
                            <Label className="form-label">Buying Price</Label>
                            <InputGroup className={validation.touched.price && validation.errors.price ? 'is-invalid' : ''}>
                                <Input
                                    name="price"
                                    placeholder="Enter Price..."
                                    type="number"
                                    min={0}
                                    onChange={handleConfirmChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.price || ""}
                                    invalid={
                                        validation.touched.price && validation.errors.price ? true : false
                                    }
                                />
                                <InputGroupText>
                                    USD / Ton
                                </InputGroupText>
                            </InputGroup>
                            {validation.touched.price && validation.errors.price ? (
                                <FormFeedback type="invalid">{validation.errors.price}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Amount Paid</Label>
                            <InputGroup className={validation.touched.share && validation.errors.share ? 'is-invalid' : ''}>
                                <Input
                                    name="share"
                                    placeholder="Enter Value..."
                                    type="number"
                                    min={0}
                                    max={100}
                                    onChange={handleConfirmChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.share || ""}
                                    invalid={
                                        validation.touched.share && validation.errors.share ? true : false
                                    }
                                />
                                <InputGroupText>
                                    % to UCO users
                                </InputGroupText>
                            </InputGroup>
                            {validation.touched.share && validation.errors.share ? (
                                <FormFeedback type="invalid">{validation.errors.share}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="d-flex justify-content-end">
                            <UpdatePriceModal handleConfirmSubmit={handleConfirmSubmit} modal_center={modal_center} tog_center={tog_center} formValues={formValues} />
                        </div>
                    </Form>
                </div>
            </Col>
            <Col md={8} sm={12}>
                <h4 className="card-title mb-0">UCO Buying Price History</h4>
                <CountryFilter selectedFilter={selectedCurrency} setSelectedFilter={setSelectedCurrency} field='currency' />

                <PriceChart selectedCurrency={selectedCurrency}/>
            </Col>
        </Row>
    )
}

export default Price