import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../helpers/api_helper";
import { InputSwitch } from 'primereact/inputswitch';
import { positiveNumericRegExp } from "constants/value";

import '../../../../assets/css/style.css'
import useBox from "hooks/useBox";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import deleteIcon from "../../../../assets/images/delete-icon.png";

import { Dropdown } from 'primereact/dropdown';
import OpeningHours from "../components/OpeningHours";
import { INITIAL_OPENING_HOURS } from "constants/value";

const AddBoxModal = () => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('id');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedMode, setSelectedMode] = useState('active');
    const [boxImages, setBoxImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [collectionPoint, setCollectionPoint] = useState(false);
    const { setRefresh, boxModes, limitImages, setLimitImages, limitImagesCount, setOpeningHoursArray, openingHoursArray, groupOptions, boxLanguages } = useBox();
    const { showToast } = useAuth();
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setBoxImages([])
        validation.resetForm();
        setSelectedMode('active')
        setSelectedLanguage('id')
        setOpeningHoursArray(INITIAL_OPENING_HOURS)
        setLimitImages(false);
    };

    let noData = { value: '', name: "Default", color: "" };
    let groupDropdown = [noData, ...groupOptions];
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: '',
            address: '',
            latitude: '',
            longitude: '',
            uco: '',
            slops: '',
            model: ''
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                "This value is required"
            ),
            uco: Yup.number("Please enter a number").integer("Please enter valid integer number").min(0, 'Please enter positive integer').required(
                "UCO Capacity is required"
            ),
            slops: Yup.number("Please enter a number").integer("Please enter valid integer number").min(0, 'Please enter positive integer').required(
                "Slops Capacity is required"
            ),
            address: Yup.string().required(
                "This value is required"
            ),
            latitude: Yup.string().required(
                "This value is required"
            ),
            longitude: Yup.string().required(
                "This value is required"
            ),
            model: Yup.string().required(
                "Model value is required"
            ),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = { ...values, mode: selectedMode, group: selectedGroup, images: boxImages, openingHours: openingHoursArray, collectionPoint: collectionPoint, language: selectedLanguage, capacity: { uco: values.uco, slops: values.slops } }
                const response = await axiosApi.post('/api/admin/engineer/box/add', opts);
                setRefresh(response.random);
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        },
    });

    const changeGroup = (e) => {
        setSelectedGroup(e.value)
    }
    const changeLanguage = (e) => {
        setSelectedLanguage(e.value)
    }
    const changeMode = (e) => {
        setSelectedMode(e.value)
    }

    const storeFiles = async (e) => {
        const { files } = e.target
        const validFiles = Array.from(files)

        // validation for no more than 10 images per box
        if (validFiles.length + boxImages.length > limitImagesCount) {
            setLimitImages(true)
            return
        }

        setUploading(true);
        await Promise.all(
            validFiles.map(async (image) => {
                const formData = new FormData();
                formData.append('image', image)

                const response = await axiosApi.post('/api/admin/engineer/box/uploads', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })

                setBoxImages(boxImages => [response.data.url, ...boxImages])
            })
        )
        setUploading(false)
    }

    const deleteImage = (e) => {
        const value = e.target.getAttribute('data-src');
        const newArray = boxImages.filter(image => image !== value);
        setBoxImages(newArray);
    }

    return (
        <>
            <Button color="primary" onClick={tog_center}> Add New Box </Button>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Add New Box</ModalHeader>
                <ModalBody className="add-modal-body">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div className="mb-3">
                            <Label className="form-label">Name</Label>
                            <Input
                                name="name"
                                placeholder="Enter Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                required
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Box Model</Label>
                            <Input
                                name="model"
                                required
                                placeholder="Enter Model"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.model || ""}
                                invalid={
                                    validation.touched.model && validation.errors.model ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Capacity</Label>
                            <ul className="d-flex justify-content-between">
                                <li>
                                    <Label className="form-label">UCO :</Label>
                                    <Input
                                        name="uco"
                                        placeholder="Enter UCO"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.uco || ""}
                                        invalid={
                                            validation.touched.uco && validation.errors.uco ? true : false
                                        }
                                    />
                                    {
                                        validation.touched.uco && validation.errors.uco ? (
                                            <FormFeedback type="invalid">{validation.errors.uco}</FormFeedback>
                                        ) : null}
                                </li>
                                <li>
                                    <Label className="form-label">Slops :</Label>
                                    <Input
                                        name="slops"
                                        placeholder="Enter Slops"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.slops || ""}
                                        invalid={
                                            validation.touched.slops && validation.errors.slops ? true : false
                                        }
                                    />
                                    {
                                        validation.touched.slops && validation.errors.slops ? (
                                            <FormFeedback type="invalid">{validation.errors.slops}</FormFeedback>
                                        ) : null}
                                </li>
                            </ul>
                        </div>

                        <div className="mb-3">
                            <Label className="form-label">Location</Label>
                            <ul className="d-flex justify-content-between">
                                <li>
                                    <Label className="form-label">Latitude :</Label>
                                    <Input
                                        name="latitude"
                                        placeholder="Enter Latitude"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.latitude || ""}
                                        invalid={
                                            validation.touched.latitude && validation.errors.latitude ? true : false
                                        }
                                    />
                                    {
                                        validation.touched.latitude && validation.errors.latitude ? (
                                            <FormFeedback type="invalid">{validation.errors.latitude}</FormFeedback>
                                        ) : null}
                                </li>
                                <li>
                                    <Label className="form-label">Longitude :</Label>
                                    <Input
                                        name="longitude"
                                        placeholder="Enter Longitude"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.longitude || ""}
                                        invalid={
                                            validation.touched.longitude && validation.errors.longitude ? true : false
                                        }
                                    />
                                    {
                                        validation.touched.longitude && validation.errors.longitude ? (
                                            <FormFeedback type="invalid">{validation.errors.longitude}</FormFeedback>
                                        ) : null}
                                </li>
                            </ul>
                        </div>

                        <OpeningHours />
                        <div className="d-flex align-items-center gap-2 mb-2">
                            <Label className="form-label m-0">Group : </Label>
                            <Dropdown
                                value={selectedGroup || ''}
                                onChange={changeGroup}
                                options={groupDropdown}
                                optionLabel="name"
                                className="h-1 payment-status-dropdown"
                            />
                        </div>
                        <div className="d-flex align-items-center gap-2 mb-2">
                            <Label className="form-label m-0">Language : </Label>
                            <Dropdown
                                value={selectedLanguage || ''}
                                onChange={changeLanguage}
                                options={boxLanguages}
                                optionLabel="name"
                                className="h-1 payment-status-dropdown"
                            />
                        </div>
                        <div className="d-flex align-items-center gap-2 mb-2">
                            <Label className="form-label m-0">Status : </Label>
                            <Dropdown
                                value={selectedMode || ''}
                                onChange={changeMode}
                                options={boxModes}
                                optionLabel="name"
                                className="h-1 payment-status-dropdown"
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Address</Label>
                            <Input
                                name="address"
                                placeholder="Enter Address"
                                rows="5"
                                type="textarea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                    validation.touched.address && validation.errors.address ? true : false
                                }
                            />
                            {validation.touched.address && validation.errors.address ? (
                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Collection Point</Label><br />
                            <InputSwitch checked={collectionPoint} onChange={(e) => setCollectionPoint(e.value)} />
                        </div>
                        <div className="mb-3">
                            <div className="d-flex gap-2 align-items-center">
                                <Label className="form-label">Images : </Label>
                                <label for="fileInput" className="btn btn-primary btn-sm">
                                    Add Image
                                </label>
                                {uploading && <Label className="form-label text-danger">uploading...</Label>}
                                {limitImages && <Label className="form-label text-danger">Max {limitImagesCount} images allowed!</Label>}
                                <input className="d-none" id="fileInput" type="file" multiple accept="image/jpeg, image/jpg, image/png" onChange={storeFiles} name="image" />
                            </div>
                            <div className="d-flex gap-2 box-images-container">
                                {boxImages.map((src) => (
                                    <div className="image-container">
                                        <img src={deleteIcon} className="box-delete-icon" width={28} height={28} data-src={src} onClick={deleteImage}></img>
                                        <img src={src} width={200} height={200} className="box-image"></img>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting || uploading}>
                                {validation.isSubmitting ? 'Submitting...' : 'Submit'}
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default AddBoxModal
