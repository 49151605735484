import useBox from "hooks/useBox";
import { Row, Col, Button, FormFeedback, Input, Label, Form, Offcanvas, OffcanvasBody, OffcanvasHeader, InputGroup, InputGroupText } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../helpers/api_helper";
import useAuth from "hooks/useAuth";
import DeleteBoxModal from "./modals/DeleteBoxModal";
import { useState, useEffect } from "react";
import moment from "moment";

import UCOFillRate from "./charts/UCOFillRate";
import deleteIcon from "../../../assets/images/delete-icon.png";
import { InputSwitch } from 'primereact/inputswitch';
import useAxiosPrivate from "hooks/useAxiosPrivate";

import { Dropdown } from 'primereact/dropdown';
import UCOSlopRate from "./charts/UCOSlopRate";
import ChamberTemp from "./charts/ChamberTemp";
import EBOXTemp from "./charts/EBOXTemp";
import BoxTransactionHistoryModal from "./modals/BoxTransactionHistoryModal";
import OpeningHours from "./components/OpeningHours";
import { INITIAL_OPENING_HOURS } from "constants/value";

const BoxDetail = ({ setVolume, setWeight, setSeries }) => {
    const axiosApi = useAxiosPrivate();
    const { auth, showToast } = useAuth();
    const { collectionPoint, setCollectionPoint, box, isRight, toggleRightCanvas, setRefreshGraph, setRefresh, setBox, boxLanguages, boxModes, selectedMode, setSelectedMode, selectedLanguage, setSelectedLanguage, selectedGroup, setSelectedGroup, boxImages, setBoxImages, limitImages, setLimitImages, limitImagesCount, openingHoursArray, setOpeningHoursArray, groupOptions, setRefreshTransaction, setBoxIdFilter } = useBox();

    const [modal_center, setmodal_center] = useState(false);
    const [modal_center2, setmodal_center2] = useState(false);
    const [uploading, setUploading] = useState(false);

    let noData = { value: 'none', name: "Default", color: "" };
    let groupDropdown = [noData, ...groupOptions];
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
        setSelectedLanguage(box?.language ?? 'id');
        setLimitImages(false);
    };

    const tog_center2 = () => {
        setmodal_center2(!modal_center2);
        removeBodyCss();
        setLimitImages(false);
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const changeMode = (e) => {
        setSelectedMode(e.value);
    }

    const changeGroup = (e) => {
        setSelectedGroup(e.value);
    }
    const changeLanguage = (e) => {
        setSelectedLanguage(e.value)
    }
    const storeFiles = async (e) => {
        setLimitImages(false)
        const { files } = e.target
        const validFiles = Array.from(files)

        // validation for no more than 10 images per box
        if (validFiles.length + boxImages.length > limitImagesCount) {
            setLimitImages(true)
            return
        }

        setUploading(true);
        await Promise.all(
            validFiles.map(async (image) => {
                const formData = new FormData();
                formData.append('image', image)

                const response = await axiosApi.post('/api/admin/engineer/box/uploads', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })

                setBoxImages(boxImages => [response.data.url, ...boxImages])
            })
        )
        setUploading(false)
    }

    const deleteImage = (e) => {
        const value = e.target.getAttribute('data-src');
        const newArray = boxImages.filter(image => image !== value);
        setBoxImages(newArray);
    }

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: box?.id,
            name: box?.name || '',
            address: box?.address || '',
            phonePrefix: box?.phone?.prefix || '',
            phoneNumber: box?.phone?.number || '',
            latitude: box?.gpsLatitude || '',
            longitude: box?.gpsLongitude || '',
            internal_id: box?.internal_id || '',
            model: box?.model || '',
            number: box?.number || '0',
            uco: box?.capacity?.uco || 0,
            slops: box?.capacity?.slops || 0,

        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                "This value is required"
            ),
            latitude: Yup.string().required(
                "This value is required"
            ),
            longitude: Yup.string().required(
                "This value is required"
            ),
            model: Yup.string().required(
                "Box Model is required"
            ),
            internal_id: Yup.string().required(
                "Box ID is required"
            ),
            number: Yup.number("Box Number => Please enter a number").integer("Box Number => Please enter valid integer number").required(
                "Box Number is required"
            ),
            uco: Yup.number("Please enter a number").integer("Please enter valid integer number").min(0, 'Please enter positive integer').required(
                "UCO Capacity is required"
            ),
            slops: Yup.number("Please enter a number").integer("Please enter valid integer number").min(0, 'Please enter positive integer').required(
                "Slops Capacity is required"
            ),



            // phonePrefix: Yup.number("Please enter a correct phone prefix").required(
            //     "Phone Prefix is required"
            // ).positive('Must be greater than zero').integer()
            // ,phoneNumber: Yup.number("Please enter a correct phone prefix").required(
            //     "Phone Number is required"
            // ).positive('Must be greater than zero').integer()
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = { ...values, mode: selectedMode, images: boxImages, openingHours: openingHoursArray, collectionPoint: collectionPoint, group: selectedGroup == 'none' ? null : selectedGroup, language: selectedLanguage, capacity: { uco: values.uco, slops: values.slops } };

                const response = await post('/api/admin/engineer/box/update', opts);
                setBoxIdFilter(null)
                setRefresh(response.random);
                setRefreshTransaction(response.random)
                setRefreshGraph(response.random);

                setBox(null);
                setBoxImages(response.highlight?.images || [])
                setSelectedMode(response.highlight.status)
                setSelectedGroup(response.highlight.group || 'none')
                setOpeningHoursArray(response.highlight?.openingHours || INITIAL_OPENING_HOURS)
                setSelectedLanguage(response.highlight.language)
                toggleRightCanvas();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });
    return (
        <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}>
            <OffcanvasHeader toggle={toggleRightCanvas}>
                Box Details
            </OffcanvasHeader>
            <OffcanvasBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                    <div className="box-information">
                        <p>ID : {box?.id}</p>
                        <Row>
                            <Col xs={3}>
                                <Label className="form-label">Number :</Label>
                                <Input
                                    name="number"
                                    placeholder="0"
                                    type="text"
                                    bsSize="lg"
                                    className="mb-2"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.number || ""}
                                    required
                                    invalid={
                                        validation.touched.number && validation.errors.number ? true : false
                                    }
                                />
                                {
                                    validation.touched.number && validation.errors.number ? (
                                        <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                                    ) : null}
                            </Col>
                            <Col xs={9}>

                                <Label className="form-label">Box ID :</Label>
                                <Input
                                    name="internal_id"
                                    placeholder="Enter Box ID"
                                    type="text"
                                    bsSize="lg"
                                    className="mb-2"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.internal_id || ""}
                                    required
                                    invalid={
                                        validation.touched.internal_id && validation.errors.internal_id ? true : false
                                    }
                                />
                                {
                                    validation.touched.internal_id && validation.errors.internal_id ? (
                                        <FormFeedback type="invalid">{validation.errors.internal_id}</FormFeedback>
                                    ) : null}
                            </Col>
                        </Row>
                        <Label className="form-label">Box Model :</Label>
                        <Input
                            name="model"
                            placeholder="Enter Box Model"
                            type="text"
                            bsSize="lg"
                            required
                            className="mb-2"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.model || ""}
                            invalid={
                                validation.touched.model && validation.errors.model ? true : false
                            }
                        />
                        {
                            validation.touched.model && validation.errors.model ? (
                                <FormFeedback type="invalid">{validation.errors.model}</FormFeedback>
                            ) : null}
                        <Label className="form-label">Name :</Label>
                        <Input
                            name="name"
                            placeholder="Enter Box Name"
                            type="text"
                            bsSize="lg"
                            className="mb-2"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                                validation.touched.name && validation.errors.name ? true : false
                            }
                        />
                        {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}

                        <p>Box Location :</p>
                        <ul className="d-flex justify-content-between">
                            <li>
                                <Label className="form-label">Latitude :</Label>
                                <Input
                                    name="latitude"
                                    placeholder="Enter Latitude"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.latitude || ""}
                                    invalid={
                                        validation.touched.latitude && validation.errors.latitude ? true : false
                                    }
                                />
                                {
                                    validation.touched.latitude && validation.errors.latitude ? (
                                        <FormFeedback type="invalid">{validation.errors.latitude}</FormFeedback>
                                    ) : null}
                            </li>
                            <li>
                                <Label className="form-label">Longitude :</Label>
                                <Input
                                    name="longitude"
                                    placeholder="Enter Longitude"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.longitude || ""}
                                    invalid={
                                        validation.touched.longitude && validation.errors.longitude ? true : false
                                    }
                                />
                                {
                                    validation.touched.longitude && validation.errors.longitude ? (
                                        <FormFeedback type="invalid">{validation.errors.longitude}</FormFeedback>
                                    ) : null}
                            </li>
                        </ul>

                        <OpeningHours />
                        <div className="d-flex align-items-center gap-2 my-3">
                            <p>Group : </p>
                            <Dropdown
                                value={selectedGroup || ''}
                                onChange={changeGroup}
                                options={groupDropdown}
                                optionLabel="name"
                                className="h-1 payment-status-dropdown"
                            />
                        </div>
                        <div className="d-flex align-items-center gap-2 mb-2">
                            <Label className="form-label m-0">Language : </Label>
                            <Dropdown
                                value={selectedLanguage || ''}
                                onChange={changeLanguage}
                                options={boxLanguages}
                                optionLabel="name"
                                className="h-1 payment-status-dropdown"
                            />
                        </div>
                        <div className="d-flex align-items-center gap-2 my-3">
                            <p>Status : </p>
                            <Dropdown
                                value={selectedMode || ''}
                                onChange={changeMode}
                                options={boxModes}
                                optionLabel="name"
                                className="h-1 payment-status-dropdown"
                            />
                        </div>

                        <p>Last Used : {box?.lastUsed ? (moment(box?.lastUsed).format('MM/DD/YYYY HH:mm:ss') + " (" + moment(box?.lastUsed).fromNow() + ")") : '-'}</p>
                        <p>Last Heartbeat : {box?.lastHeartbeat ? (moment(box?.lastHeartbeat).format('MM/DD/YYYY HH:mm:ss') + " (" + moment(box?.lastHeartbeat).fromNow() + ")") : '-'}</p>

                        <div className="d-flex justify-content-between my-2">
                            <div className="w-50">
                                <p>UCO-Tank Total Capacity :
                                    <InputGroup className={validation.touched.uco && validation.errors.uco ? 'is-invalid' : ''}>
                                        <Input
                                            name="uco"
                                            placeholder="Enter UCO"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.uco || ""}
                                            invalid={
                                                validation.touched.uco && validation.errors.uco ? true : false
                                            }
                                        />
                                        <InputGroupText>
                                            ltr
                                        </InputGroupText>
                                    </InputGroup>
                                    {validation.touched.uco && validation.errors.uco ? (
                                        <FormFeedback type="invalid">{validation.errors.uco}</FormFeedback>
                                    ) : null}
                                </p>

                                <UCOFillRate />
                            </div>
                            &nbsp;
                            <div className="w-50">

                                <p>Slops-Tank Total Capacity :
                                    <InputGroup className={validation.touched.slops && validation.errors.slops ? 'is-invalid' : ''}>
                                        <Input
                                            name="slops"
                                            placeholder="Enter Slops"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.slops || ""}
                                            invalid={
                                                validation.touched.slops && validation.errors.slops ? true : false
                                            }
                                        />
                                        <InputGroupText>
                                            ltr
                                        </InputGroupText>
                                    </InputGroup>
                                    {validation.touched.slops && validation.errors.slops ? (
                                        <FormFeedback type="invalid">{validation.errors.slops}</FormFeedback>
                                    ) : null}

                                </p>
                                <UCOSlopRate />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between my-2">
                            <div className="w-50">
                                <p>Chamber Temp : </p>
                                <ChamberTemp />
                            </div>
                            <div className="w-50">
                                <p>Electronic Box Temp : </p>
                                <EBOXTemp />
                            </div>
                        </div>


                    </div>
                    <div className="mb-3">
                        <Label className="form-label">Box Address :</Label>
                        <Input
                            name="address"
                            placeholder="Enter Address"
                            type="textarea"
                            rows="5"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                                validation.touched.address && validation.errors.address ? true : false
                            }
                        />
                        {validation.touched.address && validation.errors.address ? (
                            <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                        ) : null}
                    </div>

                    {/* 
                    
                    Olivier doesn't want it here. maybe we will use this again when the alert system that langgam requested is live

                    <Label className="form-label">Box Contact Phone Number:
                    <br></br>
                    <span class="text-muted">This will be used for receiving a Pickup message</span>
                    </Label>
            
                    <Row>
                        <Col xs={3}>
                        <Label className="form-label">Prefix :</Label>
                        <Input
                            name="phonePrefix"
                         
                            type="text"
                            bsSize="lg"
                            className="mb-2"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phonePrefix || ""}
                            required
                            invalid={
                                validation.touched.phonePrefix && validation.errors.phonePrefix ? true : false
                            }
                        />
                        </Col>
                        <Col xs={9}>
                        
                        <Label className="form-label">Number :</Label>
                        <Input
                            name="phoneNumber"
                       
                            type="text"
                            bsSize="lg"
                            className="mb-2"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phoneNumber || ""}
                            required
                            invalid={
                                validation.touched.phoneNumber && validation.errors.phoneNumber ? true : false
                            }
                        />
                        </Col>
                        </Row> */}

                    <hr></hr>
                    <div className="row">
                        <div className="col-6 mb-3">
                            <Label className="form-label">Collection Point:</Label><br />
                            <InputSwitch checked={collectionPoint} onChange={(e) => setCollectionPoint(e.value)} />

                            <br />
                            <span class="text-muted">If enabled, this box will be used to receive pickup requests.<br /></span>

                        </div>

                    </div>
                    <div className="mb-3">
                        <div className="d-flex gap-2 align-items-center">
                            <Label className="form-label">Images : </Label>
                            <label for="fileInput" className="btn btn-primary btn-sm">
                                Add Image
                            </label>
                            {uploading && <Label className="form-label text-danger">uploading...</Label>}
                            {limitImages && <Label className="form-label text-danger">Max {limitImagesCount} images allowed!</Label>}
                            <input className="d-none" id="fileInput" type="file" multiple accept="image/jpeg, image/jpg, image/png" onChange={storeFiles} name="image" />
                        </div>
                        <div className="d-flex gap-2 box-images-container">
                            {boxImages.map((src) => (
                                <div className="image-container">
                                    <img src={deleteIcon} className="box-delete-icon" width={28} height={28} data-src={src} onClick={deleteImage}></img>
                                    <img src={src} width={200} height={200} className="box-image"></img>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="d-flex flex-wrap gap-2 justify-content-between">
                        <Button color="danger" onClick={tog_center} disabled={validation.isSubmitting}>
                            Delete
                        </Button>
                        <Button color="info" onClick={tog_center2} disabled={validation.isSubmitting}>
                            Transactions
                        </Button>
                        <Button type="submit" color="primary" disabled={validation.isSubmitting || uploading}>
                            {validation.isSubmitting ? 'Updating...' : 'Update'}
                        </Button>{" "}
                    </div>
                </Form>
                {/* <UCOFillRate/> */}
                <DeleteBoxModal modal_center={modal_center} tog_center={tog_center} setVolume={setVolume} setWeight={setWeight} setSeries={setSeries} />
                <BoxTransactionHistoryModal modal_center={modal_center2} tog_center={tog_center2} data={box} />
            </OffcanvasBody>
        </Offcanvas>
    )
}

export default BoxDetail