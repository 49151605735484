import { currencyFormatter, balanceFormatter } from 'helpers/functions';
import usePrice from 'hooks/usePrice';
import React, { Component, useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { MONTHS_IN_YEAR, TON_TO_LITER } from 'constants/value';


const PriceChart = ({selectedCurrency}) => {
    const getMonthAbbreviation = (date) => {
        return date.toLocaleString('en-US', { month: 'short' });
    };

    const getLast12Months = () => {
        const months = [];
        const currentDate = new Date();
        for (let i = 0; i < 12; i++) {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
            months.unshift(getMonthAbbreviation(date));
        }
        return months;
    };
    const axiosApi = useAxiosPrivate();
    const { buySeries } = usePrice();
    let idrRate = 0;
    const [options, setOptions] = useState(null);
    const fetchIdrRate = async () => {
        const response = await axiosApi.get(`/api/admin/finance/get-currencies`);
        idrRate = response.data.rates[selectedCurrency]
        setOptions({
            colors: ['#38a4f8', '#02a499'],
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false,
                }
            },
            legend: {
                show: false
            },

            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            grid: {
                borderColor: '#f8f8fa',
                row: {
                    colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: getLast12Months(),
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                }
            },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        return `$${currencyFormatter(value)}`
                    }
                }
            },
            legend: {
                show: true,
            },
            tooltip: {
                y: {
                    formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {

                        return seriesIndex === 1 ?
                            `$${currencyFormatter(value)}/t (${balanceFormatter((value * idrRate) / TON_TO_LITER, selectedCurrency)}/ltr)`
                            :
                            `$${currencyFormatter(value)}/t`
                    }
                }
            }
        })
    }

    useEffect(() => {
        fetchIdrRate();
    }, [selectedCurrency]);

    // const [options, setOptions] = useState({
    //     colors: ['#38a4f8', '#02a499'],
    //     chart: {
    //         zoom: {
    //             enabled: false
    //         },
    //         toolbar: {
    //             show: false,
    //         }
    //     },
    //     legend: {
    //         show: false
    //     },

    //     dataLabels: {
    //         enabled: false
    //     },
    //     stroke: {
    //         curve: 'smooth',
    //         width: 2,
    //     },
    //     grid: {
    //         borderColor: '#f8f8fa',
    //         row: {
    //             colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
    //             opacity: 0.5
    //         },
    //     },
    //     xaxis: {
    //         categories: getLast12Months(),
    //         axisBorder: {
    //             show: false
    //         },
    //         axisTicks: {
    //             show: false
    //         }
    //     },
    //     yaxis: {
    //         labels: {
    //             formatter: (value) => {
    //                 return `$${currencyFormatter(value)}`
    //             }
    //         }
    //     },
    //     legend: {
    //         show: true,
    //     },
    //     tooltip: {
    //         y: {
    //             formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                    
    //                 return seriesIndex === 1 ?
    //                     `$${currencyFormatter(value)}/t (${balanceFormatter((value * idrRate) / TON_TO_LITER, selectedCurrency)}/ltr)`
    //                     :
    //                     `$${currencyFormatter(value)}/t`
    //             }
    //         }
    //     }
    // });
    return (
        <React.Fragment>
            {options && <ReactApexChart options={options} series={buySeries} type="line" height="290" />}
        </React.Fragment>
    );
}

export default PriceChart;