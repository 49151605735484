import React, { useEffect, useState } from "react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { Calendar } from 'primereact/calendar';
import CountryFilter from "pages/CountryFilter";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import LeaderTable from "./LeaderTable";
import useUser from "hooks/useLeaderboard";
import { useLocation } from "react-router-dom";

const Leaderboard = () => {
    const location = useLocation();
    const propsData = location.state;
    const { setUsers, setTotalRecords, expandedRows, setExpandedRows } = useUser();
    const [selectedCurrency, setSelectedCurrency] = useState('IDR');

    const [lazyState, setlazyState] = useState({
        searchText: '',
        config: {
            first: 0,
            rows: 50,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    });
    //get today - 30 days
    const today = new Date();
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1))

    const [endDate, setEndDate] = useState(today);

    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();

    const parseDateStartDate = (inp) => {
        if(inp == '' || inp==null) return ''
        const date = new Date(inp.toString());
        date.setHours(0, 0, 0, 0);

        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');

        const formattedUTCDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        // console.log('start date' + formattedUTCDate)

        const inputDate = inp.toString();
        const parts = inputDate.split("/");
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        // console.log(formattedDate)
        return formattedUTCDate
    }
    const parseDateEndDate = (inp) => {
        if (inp == '' || inp == null) return ''
        const date = new Date(inp.toString());
        date.setHours(23, 59, 59, 999);

        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');

        const formattedUTCDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        // console.log('end date' + formattedUTCDate)

        const inputDate = inp.toString();
        const parts = inputDate.split("/");
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        // console.log(formattedDate)
        return formattedUTCDate
    }
    const getUsers = async () => {
        try {
            const response = await axiosApi.get(`/api/admin/support/leaderboard?currency=${selectedCurrency}&startDate=${parseDateStartDate(startDate)}&endDate=${parseDateEndDate(endDate)}`);
            // console.log(response.data.result)
            setUsers(response.data.result);
            setTotalRecords(50)
            setLoading(false);
            // if (propsData) {
            //     let obj = {};
            //     obj[propsData] = true
            //     setExpandedRows(obj)
            // }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getUsers();
        }, 300);
    }, [lazyState, startDate, endDate, selectedCurrency]);

    document.title = `${process.env.REACT_APP_NAME || ''} | User Leaderboard`;
    return (
        <React.Fragment>
            <div className="page-content">

                <div className="container-fluid">
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8} sm={12}>
                                <h6 className="page-title">Leaderboard</h6>
                                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Users</a></li><li className="active breadcrumb-item" aria-current="page">Leaderboard</li></ol>
                            </Col>

                            <Col md={4} sm={12}>
                                <div className="d-flex flex-wrap justify-content-end align-items-center w-100">
                                    <CountryFilter selectedFilter={selectedCurrency} setSelectedFilter={setSelectedCurrency} field='currency' />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="card">
                        <Row>
                            <Col md={6}>
                                <div className="card p-4">

                                    <h5>Start Date</h5>
                                    <Calendar locale="en" maxDate={endDate} value={startDate} onChange={(e) => setStartDate(e.value)} showIcon />

                                </div></Col>

                            <Col md={6}>
                                <div className="card p-4">
                                    <h5>End Date</h5>
                                    <Calendar locale="en" minDate={startDate} maxDate={today} value={endDate} onChange={(e) => setEndDate(e.value)} showIcon />
                                </div>
                            </Col>

                        </Row>
                        <LeaderTable startDate={parseDateStartDate(startDate)} endDate={parseDateEndDate(endDate)} loading={loading} id={propsData} lazyState={lazyState} setlazyState={setlazyState} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Leaderboard;
